.navbar {
    box-shadow: 0 0 2rem 0 rgba(#212529, 0.1);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.75);
    &.active {
        color: rgba(0, 0, 0, 0.8);
        font-weight: 500;
    }
    &:hover{
        color: rgba(0, 0, 0, 1);
    }
}