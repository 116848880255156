.form {
  height: 3.4rem;
  transition: all 0.3s;
  .form-control {
    background-color: transparent;
  }
  .form-control:focus {
    background-color: transparent;
    box-shadow: none;
  }
  &.border {
    border-color: rgb(0 0 0 / 25%) !important;
  }
  &:focus-within {
    border-color: #19b7ea !important;
  }
}
.loader {
  color: #666;
  z-index: -1;
  &.show {
    z-index: 10;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
