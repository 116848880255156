.btn {
  line-height: 1;
  font-weight: 500;
  color: #fff;
  &:focus {
    color: #fff !important;
  }
  &:hover {
    color: #fff !important;
  }
  &:disabled {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
}
