.spinner {
  height: 40px;

  span {
    animation: sk-stretchdelay 1.2s ease-in-out infinite;
    background-color: #00696d;
    display: inline-block;
    height: 100%;
    width: 6px;
  }
  .rect2 {
    animation-delay: -1.1s;
    background-color: #00787c;
  }
  .rect3 {
    animation-delay: -1s;
    background-color: #00878c;
  }
  .rect4 {
    animation-delay: -0.9s;
    background-color: #1aa1a5;
  }
  .rect5 {
    animation-delay: -0.8s;
    background-color: #33abaf;
  }
  .rect6 {
    animation-delay: -0.7s;
    background-color: #4db6b9;
  }
  .rect7 {
    animation-delay: -0.6s;
    background-color: #66c0c3;
  }
  .rect8 {
    animation-delay: -0.5s;
    background-color: #80cbcd;
  }
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  to {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  to {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
