.alert-danger {
    background-color: #e34b4b;
    border-color: #f5c2c7;
    z-index: 10;
    .btn {
        color: rgba(#fff, .75);
        border: none;

        &:hover {
            color: white;
            border: none;
        }

        &:focus {
            box-shadow: none;
            border: none;
        }
    }
}