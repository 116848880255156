$font-family-sans-serif: Roboto, sans-serif;
// focus state box-shadow
$btn-focus-width: 0.05rem; 
$input-btn-focus-width: 0; 

// body
// $body-bg: #f2f2f2;
$body-color: #495057;

// container
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1280px
);
