main.home {
  section.main {
    background: #8e44ad;
    color: white;
  }
  .btn.encrypt {
    background: #9957b5;
    color: white;
    &:hover {
      background: #b07cc6;
    }
    &:active,
    &:focus {
      border: 1px solid rgba(#fff, 0.5);
    }
  }
}
