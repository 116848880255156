.requirements .icon {
  height: 1rem;
  width: 1rem;
}
.icon.drive {
  height: 2.5rem;
  width: 2.5rem;
}
.icon.close {
  height: 1.8rem;
}
.icon.upload {
  height: 1.2rem;
}
.icon.download {
  height: 1.2rem;
}
.icon.copy {
  height: 1rem;
  width: 1rem;
}
.icon.ok {
  height: 1rem;
  width: 1rem;
  display: none;
}
.icon.error {
  height: 1rem;
  width: 1rem;
  display: none;
}
.icon.spinner {
  height: 1rem;
  width: 1rem;
  animation: rotate-keyframes 1s linear infinite;
  color: #d0d7de;
  display: none;
}
@keyframes rotate-keyframes {
  100% {
    transform: rotate(360deg);
  }
}
