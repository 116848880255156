body {
  letter-spacing: 0.05rem;
}
.container {
  min-width: 360px;
}
.fade:not(.show) {
  height: 0;
  margin-top: 0 !important;
  z-index: -10;
}
